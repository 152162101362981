import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment-timezone';
import { ExportIcon } from 'assets/icon/dynamic';
// import { FilterIcon } from 'assets/icon';
import { Bar, Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js';
import { saveAs } from 'file-saver';
import {
  fetchTrafficAnalyticDetails,
  fetchProfileAnalyticDetails,
} from 'state/actions/mediaAnalytic';
import './MediaAnalyticDashboard.scss';

Chart.plugins.register(ChartDataLabels);
Chart.defaults.global.legend.display = true;
Chart.defaults.global.legend.position = 'bottom';

// eslint-disable-next-line func-names
Chart.elements.Rectangle.prototype.draw = function () {
  const { ctx } = this._chart;
  const vm = this._view;
  const { borderWidth } = vm.borderWidth;
  const cornerRadius = 6;
  let left; let right; let top; let bottom; let borderSkipped; let signX; let signY;

  if (!vm.horizontal) {
    left = vm.x - vm.width / 2;
    right = vm.x + vm.width / 2;
    top = vm.y;
    bottom = vm.base;
    signX = 1;
    signY = bottom > top ? 1 : -1;
    borderSkipped = vm.borderSkipped || 'bottom';

    // eslint-disable-next-line no-console
    console.log(`signX: ${signX}, signY: ${signY}`);
  }

  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  const corners = [
    [left, bottom],
    [left, top],
    [right, top],
    [right, bottom],
  ];

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = borderWidth;

  // Find first (starting) corner with fallback to 'bottom'
  const borders = ['bottom', 'left', 'top', 'right'];
  let startCorner = borders.indexOf(borderSkipped, 0);
  if (startCorner === -1) {
    startCorner = 0;
  }

  function cornerAt(index) {
    return corners[(startCorner + index) % 4];
  }

  // Draw rectangle with rounded corners
  let corner = cornerAt(0);
  ctx.moveTo(corner[0], corner[1]);

  for (let i = 1; i < 4; i += 1) {
    corner = cornerAt(i);
    let nextCornerId = i + 1;
    if (nextCornerId === 4) {
      nextCornerId = 0;
    }

    const width = corners[2][0] - corners[1][0];
    const height = corners[0][1] - corners[1][1];
    const x = corners[1][0];
    const y = corners[1][1];

    let radius = cornerRadius;
    // Fix radius being too large
    if (radius > Math.abs(height) / 2) {
      radius = Math.floor(Math.abs(height) / 2);
    }
    if (radius > Math.abs(width) / 2) {
      radius = Math.floor(Math.abs(width) / 2);
    }

    if (height < 0) {
      // Negative values in a standard bar chart
      const xTL = x;
      const xTR = x + width;
      const yTL = y + height;
      const yTR = y + height;

      const xBL = x;
      const xBR = x + width;
      const yBL = y;
      const yBR = y;

      // Draw
      ctx.moveTo(xBL + radius, yBL);
      ctx.lineTo(xBR - radius, yBR);
      ctx.quadraticCurveTo(xBR, yBR, xBR, yBR - radius);
      ctx.lineTo(xTR, yTR + radius);
      ctx.quadraticCurveTo(xTR, yTR, xTR - radius, yTR);
      ctx.lineTo(xTL + radius, yTL);
      ctx.quadraticCurveTo(xTL, yTL, xTL, yTL + radius);
      ctx.lineTo(xBL, yBL - radius);
      ctx.quadraticCurveTo(xBL, yBL, xBL + radius, yBL);
    } else {
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height
      );
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
    }
  }

  ctx.fill();
  if (borderWidth) {
    ctx.stroke();
  }
};

const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

const convertToCSV = (data) => {
  return data
    .map((row) =>
      row
        .map((item) =>
          typeof item === 'string' && item.includes(',') ? `"${item}"` : item
        )
        .join(',')
    )
    .join('\n');
};

const MediaAnalyticDashboard = () => {
  const { siteId, siteName, mediaId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([]);

  // const [selectedPeriod, setSelectedPeriod] = useState('Show All');
  const [, setSelectedDevice] = useState([]);
  // const [locationOpen, setLocationOpen] = useState(false);

  const [details, setDetails] = useState({
    mediaId: mediaId || '',
    startDate: '',
    endDate: '',
  });

  const {
    screens,
    trafficDetails: trafficDetailsGlobal,
    profileDetails: profileDetailsGlobal,
  } = useSelector(
    (state) => ({
      screens: state.screens.list,
      trafficDetails: state.mediaanalytic.trafficDetails,
      profileDetails: state.mediaanalytic.profileDetails,
    }),
    shallowEqual
  );

  const [showExportOptions, setShowExportOptions] = useState(false);

  const createCSVData = useCallback(
    (profileDetails, trafficDetails, dateRangeInput) => {
      const headers = [
        'Date',
        'Age Group',
        'Gender',
        'Today Loops',
        'All time Loops',
        'Today Traffic',
        'All time Traffic',
      ];

      const rows = [];
      const currentDate =
        dateRangeInput && dateRangeInput[0]
          ? formatDate(dateRangeInput[0])
          : formatDate(new Date());

      const ageGroups = profileDetails?.ageBreakdown || [];
      const genders = ['Male', 'Female'];

      ageGroups.forEach((ageGroup) => {
        genders.forEach((gender) => {
          rows.push([
            currentDate,
            ageGroup.ageGroup,
            gender,
            trafficDetails?.numberOfLoopsToday || 0,
            trafficDetails?.numberOfLoopsAllTime || 0,
            trafficDetails?.peopleWatchingToday || 0,
            trafficDetails?.peopleWatchingAllTime || 0,
          ]);
        });
      });

      return [headers, ...rows];
    },
    []
  );

  const handleExportCSV = useCallback(() => {
    const data = createCSVData(profileDetailsGlobal, trafficDetailsGlobal, dateRange);
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const fileName = `media_analytics_${
      dateRange && dateRange[0]
        ? formatDate(dateRange[0])
        : formatDate(new Date())
    }.csv`;
    saveAs(blob, fileName);
  }, [profileDetailsGlobal, trafficDetailsGlobal, dateRange, createCSVData]);

  const ExportDropdown = useCallback(
    () => (
      <div className="dropdown">
        <button
          className="btn-outline-primary"
          onClick={() => setShowExportOptions(!showExportOptions)}
        >
          <ExportIcon color="#64D5D2" size={18} />
          Export Data
        </button>
        {showExportOptions && (
          <div className="dropdown-content">
            <button onClick={handleExportCSV}>Export Raw Data</button>
          </div>
        )}
      </div>
    ),
    [showExportOptions, handleExportCSV]
  );

  useEffect(() => {
    if (screens.length > 0) {
      const screenIds = screens.map(
        (screen) => screen.adsumScreenId || screen._id
      );
      setSelectedDevice(screenIds);
    }
  }, [screens]);

  const [, setIsLoading] = useState(true);
  const [, setError] = useState(null);

  useEffect(() => {
    try {
      setIsLoading(true);
      dispatch(fetchTrafficAnalyticDetails(details));
      dispatch(fetchProfileAnalyticDetails(details));
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [details]); // eslint-disable-line


  // const handleExport = () => {
  //   dispatch(
  //     downloadMediaAnalytic({
  //       start: dateRange[0] ? dateRange[0].valueOf() : null,
  //       end: dateRange[1] ? dateRange[1].valueOf() : null,
  //       adsumScreenIds: selectedDevice,
  //       siteId,
  //     })
  //   );
  // };

  // const periodOptions = [
  //   { label: 'Today', value: 'Today' },
  //   { label: 'This Week', value: 'This Week' },
  //   { label: 'This Month', value: 'This Month' },
  // ];

  // const handlePeriodChange = useCallback((value) => {
  //   setSelectedPeriod(value);
  //   setLocationOpen(false);  // Close dropdown after selection

  //   const today = moment.tz('Asia/Singapore');
  //   let startDate; let endDate;

  //   switch (value) {
  //     case 'Today':
  //       startDate = today.clone().startOf('day');
  //       endDate = today.clone().endOf('day');
  //       break;
  //     case 'This Week':
  //       startDate = today.clone().startOf('week');
  //       endDate = today.clone().endOf('week');
  //       break;
  //     case 'This Month':
  //       startDate = today.clone().startOf('month');
  //       endDate = today.clone().endOf('day');
  //       break;
  //     default:
  //       startDate = null;
  //       endDate = null;
  //   }

  //   if (startDate && endDate && startDate.isValid() && endDate.isValid()) {
  //     setDateRange([startDate, endDate]);
  //     setDetails(prev => ({
  //       ...prev,
  //       startDate: startDate.format('YYYY-MM-DD'),
  //       endDate: endDate.format('YYYY-MM-DD'),
  //     }));
  //   } else {
  //     setDateRange([]);
  //     setDetails(prev => ({
  //       ...prev,
  //       startDate: '',
  //       endDate: '',
  //     }));
  //   }
  // }, []);


  const handleDateChange = useCallback((dates) => {
    if (!dates || dates.length === 0) {
      setDateRange([]);
      setDetails((prev) => ({
        ...prev,
        startDate: '',
        endDate: '',
      }));
      return;
    }

    if (dates.length === 2 && dates[0].isValid() && dates[1].isValid()) {
      setDateRange([dates[0].clone(), dates[1].clone()]);
      setDetails((prev) => ({
        ...prev,
        startDate: dates[0].format('YYYY-MM-DD'),
        endDate: dates[1].format('YYYY-MM-DD'),
      }));
    }
  }, []);

  const formatNumber = (num) => {
    if (!num) return '0';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const fullLabels = [
    'Babies (0-2 Years)',
    'Children (3-11 Years)',
    'Teenagers (12-20 Years)',
    'Young Adults (21-30 Years)',
    'Middle-Aged Adults (31-45 Years)',
    'Old-Adults (45+ Years)',
  ];

  const ageGroupData = {
    labels: [
      'Babies\n(0-2 Years)',
      'Children\n(3-11 Years)',
      'Teenagers\n(12-20 Years)',
      'Young Adults\n(21-30 Years)',
      'Middle-Aged Adults\n(31-45 Years)',
      'Old-Adults\n(45+ Years)',
    ],
    datasets: [
      {
        data: profileDetailsGlobal?.ageBreakdown?.map((item) => {
          return (item.maleWatching || 0) + (item.femaleWatching || 0);
        }) || [0, 0, 0, 0, 0, 0], // Fallback to an array of zeros if ageBreakdown is empty
        backgroundColor: '#2C82F6',
        borderColor: '#2C82F6',
        borderWidth: 1,
        barPercentage: 0.5,
      },
    ],
  };

  const genderData = {
    labels: ['Female', 'Male'],
    datasets: [
      {
        data: [
          (profileDetailsGlobal?.ageBreakdown || []).reduce(
            (sum, item) => sum + (item.femaleWatching || 0),
            0
          ),
          (profileDetailsGlobal?.ageBreakdown || []).reduce(
            (sum, item) => sum + (item.maleWatching || 0),
            0
          ),
        ],
        backgroundColor: ['#EC44E7', '#3E6EEA'],
        borderColor: ['#EC44E7', '#3E6EEA'],
        borderWidth: 2,
        hoverBackgroundColor: ['#EC44E7', '#3E6EEA'],
        hoverBorderColor: ['#EC44E7', '#3E6EEA'],
      },
    ],
  };

  const ageGroupChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            fontSize: 11,
            padding: 5,
            callback(value) {
              // Split the label into lines and return them
              const label = value.split('\n');
              return label;
            },
          },
          barPercentage: 0.5,
          categoryPercentage: 0.8,
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            callback: (value) => {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            padding: 20,
            fontSize: 12,
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      callbacks: {
        title: (tooltipItems) => {
          return fullLabels[tooltipItems[0].index];
        },
        label: (tooltipItem, data) => {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
      },
    },
    plugins: {
      datalabels: {
        display: (context) => {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value > 0;
        },
        anchor: 'end',
        align: 'top',
        color: '#000',
        formatter: (value) => {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        offset: -2,
        clamp: true,
        clip: false,
        font: (context) => {
          const { width } = context.chart.width;
          let size;
          if (width < 400) {
            size = 8;
          } else if (width < 600) {
            size = 10;
          } else {
            size = 12;
          }

          return {
            size,
          };
        },
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: 4,
        padding: 4,
      },
    },
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 10,
      },
    },
  };

  const textCenter = {
    id: 'textCenter',
    beforeDraw: (chart) => {
      const { ctx, chartArea } = chart;

      if (!chartArea) return;

      const width = chartArea.right - chartArea.left;
      const height = chartArea.bottom - chartArea.top;

      ctx.restore();

      // Calculate center position
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;

      // Draw total number
      const total = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
      const countFontSize = Math.min(width * 0.15, height * 0.15, 24);
      ctx.font = `bold ${countFontSize}px Arial`;
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#21486C';
      ctx.fillText(
        total.toLocaleString(),
        centerX,
        centerY - countFontSize / 2
      );

      // Draw "Visitors" text
      const labelFontSize = Math.min(width * 0.12, height * 0.12, 20);
      ctx.font = `${labelFontSize}px Arial`;
      ctx.fillStyle = '#21486C';
      ctx.fillText('Visitors', centerX, centerY + countFontSize / 2);

      ctx.save();
    },
  };


  const genderChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 75,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 10,
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 12,
        padding: 20,
        fontColor: '#21486C',
        generateLabels: (chart) => {
          const { data } = chart;
          const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
          return data.labels.map((label, i) => {
            const value = data.datasets[0].data[i];
            const percentage =
              total > 0 ? ((value / total) * 100).toFixed(1) : '0.0';
            return {
              text: `${label} (${value.toLocaleString()}) ${percentage}%`,
              fillStyle: data.datasets[0].backgroundColor[i],
              strokeStyle: data.datasets[0].borderColor[i],
              lineWidth: data.datasets[0].borderWidth,
              hidden: false,
              index: i,
            };
          });
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: '#fff',
        font: {
          weight: 'bold',
          size: 11,
        },
        formatter: (value, ctx) => {
          const total = ctx.dataset.data.reduce((a, b) => a + b, 0);
          const percentage =
            total > 0 ? ((value / total) * 100).toFixed(1) : '0.0';
          return `${percentage}%`;
        },
      },
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          const label = data.labels[tooltipItem.index];
          const total = dataset.data.reduce((a, b) => a + b, 0);
          const percentage =
            total > 0 ? ((value / total) * 100).toFixed(1) : '0.0';
          return `${label} (${value.toLocaleString()}) ${percentage}%`;
        },
      },
    },
  };

  useEffect(() => {
    if (profileDetailsGlobal?.data?.ageBreakdown) {
      const ageChart = document.querySelector('.age-group-distribution canvas');
      const genderChart = document.querySelector('.gender-distribution canvas');

      if (ageChart) {
        const ageChartInstance = Chart.instances[ageChart.id];
        if (ageChartInstance) {
          ageChartInstance.update();
        }
      }

      if (genderChart) {
        const genderChartInstance = Chart.instances[genderChart.id];
        if (genderChartInstance) {
          genderChartInstance.update();
        }
      }
    }
  }, [profileDetailsGlobal]);

  const dateLabel = details.startDate !== '' && details.endDate !== ''? "Selected Date" : "This Month";

  return (
    <div className="siteinfo">
      <div className="media-info">
        <div className="media-header">
          <div className="media-details">
            <h2>{trafficDetailsGlobal?.mediaName || "Media's Name"}</h2>
            <p>
              <strong>Location:</strong>{' '}
              {trafficDetailsGlobal?.mediaCategory || 'Location'}
            </p>
          </div>
          <button
            onClick={() => history.push(`/${siteId}/information/${siteName}`)}
            className="button-primary"
          >
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: '8px' }}
            >
              <path
                d="M5.66667 10.6666L1 5.99992M1 5.99992L5.66667 1.33325M1 5.99992L13 5.99992"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </button>
        </div>

        <div className="header-controls">
          <div className="filter-wrapper">
            {/* <div
              className="playlist-wrapper"
              onClick={() => setLocationOpen(!locationOpen)}
            >
              <div className="playlist-selection">
                <FilterIcon />
                <span>{selectedPeriod || 'Select Period'}</span>
              </div>
              <i
                className={`feather-chevron-down ${
                  locationOpen ? 'playlist-open' : ''
                }`}
              />
              {locationOpen && (
                <div className="playlist-dropdown">
                  {periodOptions.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => handlePeriodChange(option.value)}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div> */}
            <DatePicker.RangePicker
              value={dateRange}
              onChange={handleDateChange}
              disabledDate={(current) => {
                if (!dateRange.length || !dateRange[0]) {
                  return false;
                }
                return current && current.isBefore(dateRange[0], 'day');
              }}
            />
          </div>

          <div className="analytic-header-right">
            <ExportDropdown />
          </div>
        </div>
      </div>

      <div className="analytics-overview">
        <div className="title">Media Analytics</div>
        <table className="analytics-table">
          <thead>
            <tr>
              <th>Estimated Loops</th>
              <th>Estimated Total Loops (All Time)</th>
              <th>{dateLabel} Traffic</th>
              <th>All Time Traffic</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{formatNumber(trafficDetailsGlobal?.numberOfLoopsToday)}</td>
              <td>{formatNumber(trafficDetailsGlobal?.numberOfLoopsAllTime)}</td>
              <td>{formatNumber(trafficDetailsGlobal?.peopleWatchingToday)}</td>
              <td>{formatNumber(trafficDetailsGlobal?.peopleWatchingAllTime)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="distribution-grid">
        <div className="age-group-distribution">
          <h3>Age Group Distribution</h3>
          <div className="chart-container">
            <Bar
              data={ageGroupData}
              options={ageGroupChartOptions}
              plugins={[ChartDataLabels]}
            />
          </div>
        </div>

        <div className="gender-distribution">
          <h3>Gender Distribution</h3>
          <div className="chart-container">
            <Doughnut
              data={genderData}
              options={genderChartOptions}
              plugins={[textCenter]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaAnalyticDashboard;
